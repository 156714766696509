
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { UsersByState } from '@/types/users'
import UserTable from '@/components/UserTable.vue'
import NavigationDrawer from '@/components/NavigationDrawer.vue'

@Component({ components: { UserTable, NavigationDrawer } })
export default class UserList extends Vue {
  @Action('users/LOAD_LIST') loadData: () => Promise<void>
  @Getter('users/byState') data: UsersByState
  @Getter('users/listLoading') listLoading: boolean
  @Getter('users/editLoading') editLoading: boolean
  @Getter('permissions/canModifyUsers') canEdit!: boolean

  editEnabled = false

  get readOnly (): boolean {
    return !this.editEnabled || !this.canEdit
  }

  created () {
    this.loadData()
  }

  get loading (): boolean {
    return this.listLoading || this.editLoading
  }

  get hasPending (): boolean {
    return this.data.pending.length > 0
  }

  get hasApproved (): boolean {
    return this.data.approved.length > 0
  }

  get hasRejected (): boolean {
    return this.data.rejected.length > 0
  }
}
