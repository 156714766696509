
class Env {
  get environment (): 'local' | 'staging' | 'production' {
    if (window.location.hostname === 'backend.universalmusic.digital') {
      return 'production'
    } else if (window.location.hostname === 'dev.backend.universalmusic.digital') {
      return 'staging'
    } else {
      return 'local'
    }
  }

  get isLive (): boolean {
    return this.environment === 'production'
  }

  get userGroup (): string {
    if (this.environment === 'production') {
      return 'AAD-InternalUsers-ConsumerToolsCMS-Prod'
    } else {
      return 'AAD-InternalUsers-ConsumerToolsCMS-NonProd'
    }
  }
}

const env = new Env()

export default env
