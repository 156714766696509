
import { defineComponent } from 'vue'
import env from '@/env'

export default defineComponent({
  setup () {
    return {
      userGroup: env.userGroup
    }
  }
})

