import { ActionContext } from 'vuex'

import { RootState, GlobalState } from '../types'
import rest from '@/rest'
import {
  AnyMessage, MSG_TYPE_SESSION_ID, MSG_TYPE_LOCK_CAMPAIGN, MSG_TYPE_UNLOCK_CAMPAIGN, MSG_TYPE_CAMPAIGN_UPDATED
} from '@/types/events'
import { ErrorPayload } from '@/rest/socket'

const state: GlobalState = {
  sessionId: null,
  socketConnected: false,
  socketLoading: false,
  socketRetry: true,
  navigationDrawerOpen: true
}

type GlobalActionContext = ActionContext<GlobalState, RootState >

const mutations = {
  SET_SESSION_ID (state: GlobalState, value: string) {
    state.sessionId = value
  },
  SET_SOCKET_CONNECTED (state: GlobalState, value: boolean) {
    state.socketConnected = value
  },
  SET_SOCKET_LOADING (state: GlobalState, value: boolean) {
    state.socketLoading = value
  },
  SET_SOCKET_RETRY (state: GlobalState, value: boolean) {
    state.socketRetry = value
  },
  SET_NAVIGATION_DRAWER_OPEN (state: GlobalState, value: boolean) {
    state.navigationDrawerOpen = value
  }
}

const actions = {
  async START_WEBSOCKET (ctx: GlobalActionContext): Promise<void> {
    rest.socket.onMessage = (msg) => ctx.dispatch('HANDLE_MESSAGE', msg)
    rest.socket.onConnect = () => ctx.dispatch('HANDLE_SOCKET_CONNECTED')
    rest.socket.onError = (msg) => ctx.dispatch('HANDLE_SOCKET_ERROR', msg)
    ctx.commit('SET_SOCKET_LOADING', true)
    rest.socket.connect()
  },
  async HANDLE_MESSAGE (ctx: GlobalActionContext, msg: AnyMessage) {
    if (msg.data.type === MSG_TYPE_SESSION_ID) {
      ctx.commit('SET_SESSION_ID', msg.data.session_id)
      rest.sessionId = msg.data.session_id
      console.log('Session ID:', msg.data.session_id)
    } else if (msg.data.type === MSG_TYPE_LOCK_CAMPAIGN) {
      await ctx.dispatch('campaigns/HANDLE_MESSAGE', msg, { root: true })
    } else if (msg.data.type === MSG_TYPE_UNLOCK_CAMPAIGN) {
      await ctx.dispatch('campaigns/HANDLE_MESSAGE', msg, { root: true })
    } else if (msg.data.type === MSG_TYPE_CAMPAIGN_UPDATED) {
      await ctx.dispatch('campaigns/HANDLE_MESSAGE', msg, { root: true })
    }
  },
  async HANDLE_SOCKET_ERROR (ctx: GlobalActionContext, p: ErrorPayload) {
    ctx.commit('SET_SOCKET_CONNECTED', false)
    ctx.commit('SET_SOCKET_LOADING', p.retry)
    ctx.commit('SET_SOCKET_RETRY', p.retry)
  },
  async HANDLE_SOCKET_CONNECTED (ctx: GlobalActionContext) {
    ctx.commit('SET_SOCKET_CONNECTED', true)
    ctx.commit('SET_SOCKET_LOADING', false)
    ctx.commit('SET_SOCKET_RETRY', false)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  getters: {
    sessionId: (state: GlobalState) => state.sessionId,
    socketStatus: (state: GlobalState) => ({
      connected: state.socketConnected,
      loading: state.socketLoading,
      retry: state.socketRetry
    }),
    navigationDrawerOpen: (state: GlobalState) => state.navigationDrawerOpen
  },
  actions
}
