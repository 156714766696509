
import { useStore } from '@/composables/use-store'
import { computed, defineComponent } from 'vue'

export default defineComponent({

  // the drawer property comes from the store
  // behind the scenes we'll use a mutation and getter
  setup () {
    const store = useStore()
    const drawer = computed({
      get: () => store.getters['global/navigationDrawerOpen'],
      set: (value) => store.commit('global/SET_NAVIGATION_DRAWER_OPEN', value)
    })

    return {
      drawer
    }
  }

})

