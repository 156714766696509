
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import CampaignSettings from '@/components/CampaignSettings'
import CampaignEditor from '@/components/CampaignEditor.vue'
import CampaignReports from '@/components/CampaignReports.vue'
import CampaignTranslations from '@/components/CampaignTranslations'
import CampaignPermissions from '@/components/CampaignPermissions.vue'
import { CampaignLock, CampaignUpdateMarker } from '@/types/campaigns'
import { User } from '@/types/users'
import SpinnerFull from '@/components/SpinnerFull.vue'
import TimeDeltaHuman from '@/components/TimeDeltaHuman.vue'
import { sleep } from '@/util'
import AppHelper from '@/AppHelper'
import CampaignMusic from '@/components/CampaignMusic'
import CampaignSidebar from '@/components/CampaignSidebar.vue'
import CampaignTokenGate from '@/components/CampaignTokenGate'
import { Campaign as CampaignType } from '@/Campaign'
import NavigationDrawer from '@/components/NavigationDrawer.vue'

@Component({
  components: {
    CampaignEditor,
    SpinnerFull,
    CampaignSettings,
    CampaignReports,
    CampaignTranslations,
    CampaignPermissions,
    CampaignMusic,
    TimeDeltaHuman,
    CampaignSidebar,
    CampaignTokenGate,
    NavigationDrawer
  }
})
export default class Campaign extends Vue {
  @Prop() id: string

  @Action('campaigns/LOAD_CURRENT') loadCampaign: (campaignID: string) => Promise<void>
  @Action('campaigns/CLEAR_CURRENT') clearCurrentCampaign: () => Promise<void>
  @Action('campaigns/LOCK_CURRENT') lockCampaign: () => Promise<void>
  @Action('campaigns/UNLOCK_CURRENT') unlockCampaign: () => Promise<void>
  @Getter('campaigns/currentLoading') loading!: boolean
  @Getter('campaigns/current') campaign!: CampaignType
  @Getter('campaigns/currentLock') lock!: CampaignLock | null
  @Getter('campaigns/currentUpdate') updateInfo!: CampaignUpdateMarker | null
  @Getter('users/current') currentUser!: User
  @Getter('global/sessionId') sessionId!: string
  @Getter('apps/currentHelper') app!: AppHelper

  stopLoop = false
  ignoredUpdate: Date | null = null

  async created () {
    await this.loadCampaign(this.id)
    document.title = 'CMS - ' + this.campaign.data.name
    if (!this.lockedByOtherUser) {
      await this.lockCampaign()
    }
    this.lockLoop()
  }

  async lockLoop () {
    while (true) {
      await sleep(15000)
      if (this.stopLoop) {
        return
      }
      if (this.lockedByOtherUser) {
        continue
      }
      await this.lockCampaign()
    }
  }

  async beforeDestroy () {
    this.stopLoop = true
    await this.unlockCampaign()
    await this.clearCurrentCampaign()
  }

  async takeOver () {
    await this.lockCampaign()
  }

  ignoreUpdate () {
    this.ignoredUpdate = this.updateInfo?.updatedAt || null
  }

  reload () {
    window.location.reload()
  }

  goBack () {
    this.$router.push({ name: 'campaigns' })
  }

  @Watch('id')
  async onIdChanged (value: string, oldValue: string): Promise<void> {
    if (value === oldValue) {
      return
    }
    await this.loadCampaign(value)
  }

  @Watch('sessionId')
  async onSessionIdChanged (value: string, oldValue: string | null) {
    if (oldValue === value) {
      return
    }

    if (!oldValue && !this.lockedByOtherUser) {
      await this.lockCampaign()
    }
    if (this.lockedByCurrentUser) {
      await this.lockCampaign()
    }
  }

  get routeName () {
    return this.$route.name
  }

  get lockedByOtherUser (): boolean {
    return this.lock !== null && this.lock.user.id !== this.currentUser.id
  }

  get lockedByCurrentUser (): boolean {
    return this.lock !== null && this.lock.user.id === this.currentUser.id
  }

  get lockUsername (): string|null {
    return this.lock?.user.display_name || null
  }

  get updatedByOther (): boolean {
    const info = this.updateInfo
    if (!info || !this.campaign) { return false }
    const campaignUpdated = new Date(this.campaign.data.updated_at)
    if (info.sessionId === this.sessionId) {
      return false
    }
    return info.updatedAt !== this.ignoredUpdate && info.updatedAt > campaignUpdated
  }

  get updateUsername (): string | null {
    return this.updateInfo?.user?.display_name || null
  }

  get updateOtherTime (): Date | null {
    return this.updateInfo?.updatedAt || null
  }
}
